import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  login,
  MFALogin,
  sendForgotPasswordCode,
  sendResetPasswordCode,
  setNewPassword,
} from 'services/authRepository';
import { parseError } from '../../../../utils/utils';
import NewPasswordForm from '../NewPasswordForm';
import LoginForm from '../LoginForm';
import ForgotPassword from '../ForgotPassword';
import PasswordWinState from './PasswordWinState';
import MFA from '../MFA';
import logo from 'assets/img/omni-logo.svg';
import './LoginPage.scss';

const LoginPage = ({ onLogin }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState('showLogin');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const Steps = {
    showLogin: () => (
      <LoginForm onSubmit={handleSubmit} cleanMessage={cleanMessages} />
    ),
    showMfa: () => <MFA onSubmit={handleMFA} />,
    showForgotPassword: () => (
      <ForgotPassword
        onSendCode={handleForgotPasswordCode}
        onResetPassword={handleResetForgottenPassword}
        code={code}
      />
    ),
    showPasswordWinState: () => (
      <PasswordWinState handleProceedToLogin={() => setStep('showLogin')} />
    ),
    showNewPassword: () => (
      <NewPasswordForm
        onSubmit={handleChangePassword}
        name={changePassword.email}
      />
    ),
  };
  const [changePassword, setChangePassword] = useState({
    email: '',
    session: '',
  });
  const [error, setError] = useState({
    showError: false,
    message: '',
  });
  const [message, setMessage] = useState();
  const [mfa, setMfa] = useState({
    mfaEnabled: false,
    email: '',
    mfaCode: '',
    session: '',
  });

  const email = searchParams.get('email');
  const tempPass = searchParams.get('tempPass');
  const code = searchParams.get('code') || '';

  const updateQueryString = query => {
    navigate(`?${query.toString()}`, { replace: true });
  };

  const handleLoginResponse = (loginData, loginEmail = '') => {
    if (loginData.success) {
      searchParams.delete('code');
      updateQueryString(searchParams);
      onLogin(
        { ...loginData, email: loginEmail, mfaEnabled: mfa.mfaEnabled },
        'employees',
      );
      return;
    }
    if (loginData.message === 'SOFTWARE_TOKEN_MFA') {
      setMfa(mfaValue => ({
        ...mfaValue,
        email: loginEmail,
        mfaEnabled: true,
        session: loginData.session,
      }));
      setStep('showMfa');
    } else if (loginData.message === 'NEW_PASSWORD_REQUIRED') {
      setChangePassword({
        email: loginEmail,
        session: loginData.session,
      });
      setStep('showNewPassword');
    } else {
      setError({
        showError: true,
        message: t('login.error'),
      });
    }
  };

  const cleanMessages = () => {
    setError({
      showError: false,
      message: '',
    });
    setMessage(null);
  };

  const handleSubmit = useCallback(async ({ email, password }) => {
    cleanMessages();
    email = email.trim();
    try {
      const loginData = await login(email, password);

      handleLoginResponse(loginData, email);
    } catch (err) {
      setError({
        showError: true,
        message: parseError(err.response?.data?.message || t('login.error')),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkQueryParams = async () => {
      if (email && tempPass) {
        handleSubmit({ email, password: tempPass });
      } else if (email && code) {
        setStep('showForgotPassword');
      }
    };
    checkQueryParams();
  }, [email, code, tempPass, handleSubmit]);

  async function handleChangePassword({ password, repeatPassword }) {
    cleanMessages();
    if (password !== repeatPassword) {
      setError({
        showError: true,
        message: t('login.passwordsNotMatch'),
      });
      return;
    }

    try {
      const newPasswordData = await setNewPassword({
        ...changePassword,
        password,
      });

      handleLoginResponse(newPasswordData, changePassword.email);
    } catch (err) {
      setError({
        showError: true,
        message: err.response?.data?.message || t('login.error'),
      });
    }
  }

  async function handleForgotPasswordCode(loginEmail, nextStep) {
    cleanMessages();
    try {
      await sendForgotPasswordCode(loginEmail);
      nextStep();
    } catch (err) {
      setError({
        showError: true,
        message: err.response?.data?.message || t('login.error'),
      });
    }
  }

  async function handleResetForgottenPassword({ newPassword }, nextStep) {
    cleanMessages();
    try {
      await sendResetPasswordCode(email, code, newPassword);
      setStep('showPasswordWinState');
      nextStep();
    } catch (err) {
      setError({
        showError: true,
        message: err.response?.data?.message || t('login.error'),
      });
    }
  }

  async function handleMFA(mfaCode) {
    cleanMessages();
    try {
      const mfaRequest = {
        email: mfa.email,
        mfaCode,
        session: mfa.session,
      };
      const response = await MFALogin(mfaRequest);
      if (response.success) {
        handleLoginResponse(response, mfa.email);
      } else {
        setError({ showError: true, message: t('login.codeNotMatch') });
      }
    } catch (err) {
      setError({
        showError: true,
        message: err.response?.data?.message || t('login.error'),
      });
    }
  }

  return (
    <div className="login-page">
      <div className="logo-incode">
        <img src={logo} alt="Incode logo" />
      </div>
      <div className="login-inner">
        {Steps[step]()}
        {error.showError && <p className="error">{error.message}</p>}
        {message && <p className="msg">{message}</p>}
      </div>
      {step === 'showForgotPassword' && (
        <div className="outer-link">
          <span>{t('login.ifYouPrefer')}</span>
          <button
            type="button"
            className="forgot-password"
            onClick={() => setStep('showLogin')}
          >
            {t('login.goToLogin')}
          </button>
        </div>
      )}
      {(step === 'showLogin' || step === 'showMfa') && (
        <div className="outer-link">
          <span>{t('login.forgotPassword')}</span>
          <button
            type="button"
            className="forgot-password"
            onClick={() => setStep('showForgotPassword')}
          >
            {t('login.getPassword')}
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
