import api from 'utils/api';

export function sendSMS(url, id, token) {
  return api
    .post(`/omni/notify/conference-connect`, { id, url }, { token })
    .then(response => response.data);
}

export function addConferenceEvent({
  token,
  interviewId,
  opentokSessionId,
  eventType,
  details,
  eventSource,
}) {
  return api
    .post(
      '/omni/conference/add/event',
      {
        interviewId,
        opentokSessionId,
        eventType,
        details,
        eventSource,
      },
      {
        headers: {
          'X-Incode-Hardware-Id': token,
        },
      },
    )
    .catch(e => Promise.reject(e.response.data));
}
