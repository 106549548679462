import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useLogin, useEncryption, useQuery } from '../../../utils/hooks';
import LoginComponent from '../auth/Login/LoginPage';
import Dashboard from './Dashboard';
import { preloadScript } from 'opentok-react';

function LoginLayout({ children }) {
  return <div className="app-wrapper">{children}</div>;
}

function Conference() {
  const query = useQuery();
  const navigate = useNavigate();
  const queueQuery = query.get('queue');

  const { isLogged, validatedUser, login, logout, token } = useLogin();
  const isReady = useEncryption({ token, isLogged });

  function handleLogin(loginData) {
    login(loginData);
    navigate(`/conference${queueQuery ? `/${queueQuery}` : ''}`);
  }

  if (!isLogged) {
    return (
      <LoginLayout>
        <Routes>
          <Route
            path="log-in"
            element={<LoginComponent onLogin={handleLogin} />}
          />
          <Route path="*" element={<Navigate to="log-in" replace />} />
        </Routes>
      </LoginLayout>
    );
  }

  if (!isReady || !validatedUser) return null;

  return (
    <Routes>
      <Route
        index
        element={
          <Dashboard logout={logout} isLogged={isLogged} token={token} />
        }
      />
      <Route
        path=":queue"
        element={
          <Dashboard logout={logout} isLogged={isLogged} token={token} />
        }
      />
      <Route path="*" element={<Navigate to="/conference" replace />} />
    </Routes>
  );
}

export default preloadScript(Conference);
